const menu = [
  {
    id: 1,
    title: 'Idly',
    category: 'tiffin',
    price: 3.49,
    img: './images/Idly.jpeg',
    desc: `Enjoy four fluffy idlis served with two chutneys and flavorful sambar—a taste of authentic South India. `,
  },
  {
    id: 2,
    title: 'Vada',
    category: 'tiffin',
    price: 3.49,
    img: './images/Vada.jpeg',
    desc: `Enjoy a serving of four crispy Vadas—crunchy on the outside, fluffy on the inside. Accompanied by flavorful chutneys and aromatic sambar, it's a delightful South Indian snack that's sure to satisfy.`,
  },
  {
    id: 3,
    title: 'Dahi Vada',
    category: 'tiffin',
    price: 3.49,
    img: './images/DahiVada.jpeg',
    desc: `Indulge in three pieces of our delightful Dahi Vada—soft lentil dumplings soaked in creamy yogurt, garnished with tangy spices.`,
  },
  {
    id: 4,
    title: 'Utthappam',
    category: 'tiffin',
    price: 3.49,
    img: './images/Utthappam.jpeg',
    desc: `Delight in a single piece of our savory Uttappam, generously garnished with carrots, onions, cumin seeds, fresh coriander, and a hint of chili—a flavorsome South Indian specialty.`,
  },
  {
    id: 5,
    title: 'Poori',
    category: 'tiffin',
    price: 3.49,
    img: './images/Poori.jpeg',
    desc: `Enjoy a serving of three golden-brown Pooris—crispy, puffed bread that's light and airy. Perfect for dipping into our aromatic curries `,
  },
  {
    id: 6,
    title: 'Gunta Ponganalu',
    category: 'tiffin',
    price: 3.49,
    img: './images/GuntaPonganalu.jpeg',
    desc: `Delight in a serving of six Guntaponganalu—crispy and savory balls of goodness, perfect for any occasion. Served hot and fresh, they're sure to satisfy your cravings. `,
  },
  {
    id: 7,
    title: 'Plain Dosa',
    category: 'tiffin',
    price: 3.49,
    img: './images/PlainDosa.jpeg',
    desc: `Enjoy our Plain Dosa - Crispy, thin crepe made from fermented rice and lentil batter, served with chutneys and sambar.`,
  },
  {
    id: 8,
    title: 'Masala Dosa',
    category: 'tiffin',
    price: 3.49,
    img: './images/MasalaDosa.jpeg',
    desc: `Enjoy our Masala Dosa - Crispy dosa stuffed with a spiced potato filling, served with chutneys and sambar. `,
  },
  {
    id: 9,
    title: 'Cheese Dosa',
    category: 'tiffin',
    price: 3.49,
    img: './images/CheeseDosa.jpeg',
    desc: `Enjoy our Cheese Dosa - Crispy dosa filled with melted cheese, combining a traditional crepe with a creamy twist.`,
  },
  {
    id: 10,
    title: 'Onion Dosa',
    category: 'tiffin',
    price: 3.49,
    img: './images/OnionDosa.jpeg',
    desc: `Enjoy our Onion Dosa - Crispy, thin crepe topped with sautéed onions and spices for a savory South Indian classic.`,
  }
  ,
  {
    id: 11,
    title: 'Mysore Bonda',
    category: 'tiffin',
    price: 3.49,
    img: './images/MysoreBonda.jpeg',
    desc: `Enjoy four Bonda Crispy golden fritters made from spiced lentil batter, a classic South Indian snack.`,
  },
  {
    id: 12,
    title: 'Upma',
    category: 'tiffin',
    price: 3.49,
    img: './images/Upma.jpeg',
    desc: `Savory semolina cooked with vegetables and spices, offering a hearty South Indian breakfast.`,
  }
  ,
  {
    id: 201,
    title: 'Veg Thali',
    category: 'main course',
    price: 4.49,
    img: './images/VegThali.jpeg',
    desc: `A delightful platter of various vegetarian dishes, served with rice, roti, and accompaniments.`,
  }
  ,
  {
    id: 202,
    title: 'Chicken Dum Biryani',
    category: 'main course',
    price: 4.49,
    img: './images/chicken-dum-biryani.jpeg',
    desc: `Aromatic basmati rice layered with tender chicken, cooked to perfection with traditional spices.`,
  }
  ,
  {
    id: 203,
    title: 'Chicken65 Biryani',
    category: 'main course',
    price: 4.49,
    img: './images/ChickenPieceBiryani.jpeg',
    desc: `Spicy fried chicken pieces mixed with flavorful biryani rice, a perfect blend of taste and tradition.`,
  }
  ,
  {
    id: 204,
    title: 'Vegetable Biryani',
    category: 'main course',
    price: 4.49,
    img: './images/veg-biryani.jpeg',
    desc: `A medley of fresh vegetables cooked with fragrant basmati rice and aromatic spices.`,
  }
  ,
  {
    id: 301,
    title: 'Punjabi Samosa',
    category: 'snack',
    price: 3.49,
    img: './images/PanjabiSamosa.jpeg',
    desc: `Crispy pastry filled with a spiced potato and pea mixture, a popular North Indian delight.`,
  },
  {
    id: 302,
    title: 'Chicken Samosa',
    category: 'snack',
    price: 3.49,
    img: './images/ChikenSamosa.jpeg',
    desc: `Golden fried pastry stuffed with seasoned chicken, a savory and crunchy treat.`,
  },
  {
    id: 303,
    title: 'Onion Pakoda',
    category: 'snack',
    price: 3.49,
    img: './images/OnionPakoda.jpeg',
    desc: `Crispy fried onion fritters, seasoned with spices for a delicious snack.`,
  },
  {
    id: 304,
    title: 'Chicken65',
    category: 'snack',
    price: 4.49,
    img: './images/Chicken65.jpeg',
    desc: `Spicy, crispy fried chicken chunks, marinated in bold, flavorful spices.`,
  },
  {
    id: 305,
    title: 'Crispy  Prawns',
    category: 'snack',
    price: 4.49,
    img: './images/CrispyPrawns.jpeg',
    desc: `Tender prawns coated in a light, crispy batter, fried to golden perfection.`,
  }
];
export default menu;
