import React, { useState,useEffect } from "react";
import Menu from "./Menu";
import Categories from "./Categories";
import items from "./data";
import logo from "./logo.jpeg";

//const allCategories = ["all", ...new Set(items.map((item) => item.category))];

const allCategories = [...new Set(items.map((item) => item.category))];

const App = () => {
  const [menuItems, setMenuItems] = useState(items);
  const [activeCategory, setActiveCategory] = useState("tiffin");
  const [categories, setCategories] = useState(allCategories);

  useEffect(() => {
    filterItems(activeCategory);
  },[activeCategory]);

  const filterItems = (category) => {
    setActiveCategory(category);
    // if (category === "all") {
    //   setMenuItems(items);
    //   return;
    // }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };
  return (
    <main>
      <section className="menu section">
        <div className="title">
          <img src={logo} alt="logo" className="logo" />
          <h2>Menu List</h2>
          <div className="underline"></div>
        </div>
        <Categories
          categories={categories}
          activeCategory={activeCategory}
          filterItems={filterItems}
        />
        <Menu items={menuItems} />
      </section>
    </main>
  );
};

export default App;
